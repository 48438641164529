<template>
  <v-row no-gutters>
    <v-col cols="12" class="text-center font-weight-bold subtitle-2">"REFUND VOUCHER"</v-col>
    <v-col cols="6" class="text-left font-weight-bold">
      <v-row no-gutters>
        <v-col cols="2">To :</v-col>
        <v-col cols="10">
          <span class="underlineVoucher">{{voucher.customer.customerName}}</span>
        </v-col>
        <v-col cols="2">Tel:</v-col>
        <v-col cols="10">
          <span class="underlineVoucher">{{voucher.customer.phone}}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6" class="text-right font-weight-bold">
      <v-row no-gutters>
        <v-col cols="11" class="ml-2">Date : {{voucher.date | voucherExpenseDate}}</v-col>
        <v-col cols="8">Vr No :</v-col>
        <v-col cols="4" class="text-center">{{voucher.refundCode}}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="body-2 text-center px-2">
      <table cellspacing="4" class="simpleTable">
        <thead>
          <tr>
            <th style="width: 10%; height: 20px;">No.</th>
            <th style="width: 40%">Particular</th>
            <th style="width: 10%">Qty</th>
            <th style="width: 15%">Price</th>
            <th style="width: 20%">Amount</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(expense,i) in voucher.refundWay">
            <tr v-if="expense.refundWayId > 0" :key="expense.refundWayId">
              <td style="width: 115px; height: 50px">{{i+1}}</td>
              <td
                style="width: 600px; height:120px; font-size: 12px; line-height: normal;"
                class="text-left"
              >
                Refund for
                <template v-for="pnr in expense.pnr">{{pnr}}</template>
                <br />
                {{expense.travelDate}} ({{expense.fromSector.shortName}} - {{expense.toSector.shortName}}) 
                <br />
                Flight - {{expense.flightName}}
                <br />
                <template v-for="fPerson in expense.forPerson">{{fPerson}}</template>
              </td>
              <td style="width: 150px" class="text-right">{{expense.pass| perssengerCount}}</td>
              <td
                v-if="voucher.moneyType == 'mmk'"
                style="width: 92px"
                class="text-right"
              >{{expense.fare| defaultPaymentFormat}}</td>
              <td
                v-else
                style="width: 92px"
                class="text-right"
              >{{expense.fare| defaultPaymentWithDollarSignFormat}}</td>
              <td
                v-if="voucher.moneyType == 'mmk'"
                style="width: 92px"
                class="text-right"
              >{{expense.amount| defaultPaymentFormat}}</td>
              <td
                v-else
                style="width: 92px"
                class="text-right"
              >{{expense.amount| defaultPaymentWithDollarSignFormat}}</td>
            </tr>
            <tr v-else :key="expense.refundWayId">
              <td style="width: 155px; height: 100px;"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </template>
          <tr>
            <th rowspan="2" class="noBorder caption">Remark:</th>
            <th rowspan="2" colspan="2" class="text-left noBorder">
              <span class="underlineVoucher">{{voucher.remark}}</span>
            </th>
            <th>Total</th>
            <td
              v-if="voucher.moneyType == 'mmk'"
              class="text-right"
            >{{voucher.total | defaultPaymentFormat}}</td>
            <td
              v-else
              class="text-right"
            >{{voucher.total | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
          <tr>
            <th>Advance</th>
            <td
              class="text-right"
              v-if="voucher.moneyType == 'mmk'"
            >{{voucher.paid | defaultPaymentFormat}}</td>
            <td
              v-else
              class="text-right"
            >{{voucher.paid | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
          <tr>
            <th colspan="3" class="text-left noBorder caption">
              Issued By:
              <span class="underlineVoucher">{{voucher.issuedBy}}</span>
            </th>
            <th>Balance</th>
            <td
              class="text-right"
              v-if="voucher.moneyType == 'mmk'"
            >{{voucher.balance | defaultPaymentFormat}}</td>
            <td
              v-else
              class="text-right"
            >{{voucher.balance | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
          </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="4" offset="7" class="text-center mt-7">
      <span class="vertical-dotted-divider body-2">Claimant's Signature/ Name</span>
    </v-col>
    <v-col cols="4" class="companyFont" v-html="company.address"></v-col>
    <v-col cols="3" class="companyFont pl-1" v-html="'Ph: '+company.phoneNo"></v-col>
    <v-col cols="5" class="text-left companyFont">
      Email: {{company.email}}
      <br />
      {{company.website}}
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    company: Object,
    voucher: {
      toCustomer: {
        customerName: "",
        phone: "",
      },
    },
  },
};
</script>

<style scoped>
.companyFont{
  font-size: 0.70em;
}
.customfont {
  font-size: 0.5em;
}
@font-face {
  font-family: "Handage";
  src: url("/font/HANDA__.woff") format("woff");
  src: url("/font/HANDA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haettenschweiler";
  src: url("/font/Haettenschweiler.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.heading-font {
  font-family: "Handage";
  letter-spacing: 0.3em;
}
.first-heading-font {
  font-family: "Haettenschweiler" !important;
  font-weight: 300;
  letter-spacing: 0.2em !important;
}
.vertical-dotted-divider {
  border-top: 2px dotted black;
}
.hori-dotted-divider {
  border-left: 2px dotted black;
}
.vertical-divider {
  border-top: 2px solid black;
}
.underlineVoucher {
  padding-bottom: 1px;
  border-bottom: dotted 2px black;
}
.simpleTable td,
.simpleTable th {
  border: 1px solid black;
  border-radius: 7px;
  /* white-space: pre-wrap; */
  padding-left: 10px;
  padding-right: 10px;
}
.noBorder {
  border: 0px !important;
}
.innerTable {
  border-collapse: collapse;
  border-style: hidden;
}
.innerTable td,
.innerTable th {
  border: 1px solid black;
}
.innerTable tr:first-child th {
  border-top: 0px;
}
.innerTable tr:last-child td {
  border-bottom: 0;
}
.innerTable tr td:first-child,
.innerTable tr th:first-child {
  border-left: 0;
}
.innerTable tr td:last-child,
.innerTable tr th:last-child {
  border-right: 0;
}

/* td::before { */
/* content: " "; */
/* } */
</style>