<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="px-1 d-print-none">
        <v-container class="py-0">
          <v-row no-gutters>
            <v-col cols="12" class="text-right hidden-print-only">
              <v-btn depressed color="primary" class="mt-1 mr-5" @click="printSaleVoucher">
                <v-icon>print</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <img :src="file" ref="cropper" style="height: 15vh;" />
            </v-col>
            <v-col cols="8" class="text-center pt-2">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="display-2 first-heading-font heading-font"
                >{{company.companyName}}</v-col>
                <v-col cols="12" class="headline font-weight-bold">
                  <span class="heading-font">TRAVELS & TOURS</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="vertical-divider"></div>
            </v-col>
            <v-col cols="12" class="text-center font-weight-bold title">"REFUND VOUCHER"</v-col>
            <v-col cols="6" class="text-left font-weight-bold">
              <v-row no-gutters>
                <v-col cols="1">To :</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.customer.customerName}}</span>
                </v-col>
                <v-col cols="1">Tel:</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">{{voucher.customer.phone}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-right font-weight-bold">
              <v-row no-gutters>
                <v-col cols="8">Date :</v-col>
                <v-col cols="4" class="text-center">{{voucher.date | voucherExpenseDate}}</v-col>
                <v-col cols="8">Vr No :</v-col>
                <v-col cols="4" class="text-center">{{voucher.refundCode}}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="caption text-center px-2">
              <table cellspacing="4" class="simpleTable">
                <thead>
                  <tr>
                    <th style="width: 100px; height: 25px;">No.</th>
                    <th style="width: 475px">Particular</th>
                    <th style="width: 152px">Qty</th>
                    <th style="width: 90px">Price<span v-if="voucher.moneyType == 'mmk'">(mmk)</span><span v-else>($)</span></th>
                    <th style="width: 80px">Amount<span v-if="voucher.moneyType == 'mmk'">(mmk)</span><span v-else>($)</span></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(expense,i) in voucher.refundWay">
                    <tr v-if="expense.refundWayId > 0" :key="expense.refundWayId">
                      <td style="width: 115px; height: 50px">{{i+1}}</td>
                      <td style="width: 600px" class="text-left">
                        Refund for
                        <template v-for="pnr in expense.pnr">{{pnr}}</template>
                        <br />
                        {{expense.travelDate}} ({{expense.fromSector.shortName}} - {{expense.toSector.shortName}}) Flight - {{expense.flightName}}
                        <br />
                        <template v-for="fPerson in expense.forPerson">{{fPerson}}</template>
                      </td>
                      <td style="width: 150px" class="text-right">{{expense.pass| perssengerCount}}</td>
                      <td
                        v-if="voucher.moneyType == 'mmk'"
                        style="width: 92px"
                        class="text-right"
                      >{{expense.fare| defaultPaymentFormat}}</td>
                      <td
                        v-else
                        style="width: 92px"
                        class="text-right"
                      >{{expense.fare| defaultPaymentWithDollarSignFormat}}</td>
                      <td
                        v-if="voucher.moneyType == 'mmk'"
                        style="width: 92px"
                        class="text-right"
                      >{{expense.amount| defaultPaymentFormat}}</td>
                      <td
                        v-else
                        style="width: 92px"
                        class="text-right"
                      >{{expense.amount| defaultPaymentWithDollarSignFormat}}</td>
                    </tr>
                    <tr v-else :key="expense.refundWayId">
                      <td style="width: 155px; height: 67px" v-if="i==1">{{i+1}}</td>
                      <td style="width: 155px; height: 67px" v-else></td>
                      <td class="text-left" v-if="i==1">{{voucher.description}}</td>
                      <td class="text-left" v-else></td>
                      <td class="text-left text-right" v-if="i==1">{{voucher.desqty| perssengerCount}}</td>
                      <td class="text-left" v-else></td>
                      <td class="text-left text-right" v-if="i==1">{{voucher.desprice| defaultPaymentFormat}}</td>
                      <td class="text-left" v-else></td>
                      <td class="text-left text-right" v-if="i==1">{{voucher.desqty*voucher.desprice| defaultPaymentFormat}}</td>
                      <td class="text-left" v-else></td>
                    </tr>
                  </template>
                  <tr>
                    <th rowspan="2" class="noBorder">Remark :</th>
                    <th rowspan="2" colspan="2" class="text-left noBorder">
                      <span class="underlineVoucher">{{voucher.remark}}</span>
                    </th>
                    <th style="width: 196px; height: 35px">Total</th>
                    <td
                      v-if="voucher.moneyType == 'mmk'"
                      style="width: 120px;height: 35px"
                      class="text-right"
                    >{{voucher.total | defaultPaymentFormat}}</td>
                    <td
                      v-else
                      class="text-right"
                      style="width: 120px;height: 35px"
                    >{{voucher.total | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
                  </tr>
                  <tr>
                    <th style="width: 196px; height: 35px">Paid</th>
                    <td
                      class="text-right"
                      v-if="voucher.paid == '0'"
                      style="width: 120px;height: 35px"
                    ></td>
                    <td
                      class="text-right"
                      v-else-if="voucher.moneyType == 'mmk'"
                      style="width: 120px;height: 35px"
                    >{{voucher.paid | defaultPaymentFormat}}</td>
                    <td
                      v-else
                      class="text-right"
                      style="width: 120px;height: 35px"
                    >{{voucher.paid | defaultPaymentWithDollarSignFormatReturnBlank}}</td>
                  </tr>
                  <tr>
                    <th class="noBorder">Issued By :</th>
                    <th colspan="2" class="text-left noBorder">
                      <span class="underlineVoucher">{{voucher.issuedBy}}</span>
                    </th>
                    <th style="width: 196px; height: 35px">Balance</th>
                    <td
                      class="text-right"
                      v-if="voucher.paid == '0'"
                      style="width: 120px;height: 35px"
                    ></td>
                    <td
                      class="text-right"
                      v-else-if="voucher.moneyType == 'mmk'"
                      style="width: 120px;height: 35px"
                    >{{voucher.total-voucher.paid}}</td>
                    <td
                      v-else
                      class="text-right"
                      style="width: 120px;height: 35px"
                    >${{voucher.total-voucher.paid }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="4" offset="8" class="text-center mt-7">
              <span class="vertical-dotted-divider caption">Claimant's Signature/ Name</span>
            </v-col>
            <v-col cols="4" class="caption" v-html="company.address"></v-col>
            <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
            <v-col cols="5" class="text-left caption">
              Email: {{company.email}}
              <br />
              {{company.website}}
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6" class="px-1 d-none d-print-block">
        <v-row no-gutters>
          <v-col cols="2">
            <img :src="file" ref="cropper" style="height: 15vh;" />
          </v-col>
          <v-col cols="8" class="text-center pt-2">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="display-1 first-heading-font heading-font"
              >{{company.companyName}}</v-col>
              <v-col cols="12" class="headline font-weight-bold">
                <span class="heading-font">TRAVELS & TOURS</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="vertical-divider"></div>
          </v-col>
          <v-col cols="12">
            <expense-voucher :company="company" :voucher="voucher"></expense-voucher>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="d-none d-print-block">
        <v-row no-gutters>
          <v-col cols="2">
            <img :src="file" ref="cropper" style="height: 15vh;" />
          </v-col>
          <v-col cols="8" class="text-center pt-2">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="display-1 first-heading-font heading-font"
              >{{company.companyName}}</v-col>
              <v-col cols="12" class="headline font-weight-bold">
                <span class="heading-font">TRAVELS & TOURS</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="vertical-divider"></div>
          </v-col>
          <v-col cols="12">
            <expense-voucher :company="company" :voucher="voucher"></expense-voucher>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import companyInfoService from "../service/CompanyInfoService";
import voucherService from "../service/VoucherService";
import expenseVoucher from "../components/VoucherExpense";
import axios from "../config";
export default {
  data() {
    return {
      voucherId: 0,
      refundId: 0,
      company: {},
      file: "",
      voucher: {
        date: "",
        customer: {
          customerName: "",
        },
        refundWay: [
          {
            forPerson: "",
            pass: 0,
          },
        ],
      },
    };
  },
  mounted: function () {
    let voucherId = this.$route.query.voucherId;
    if (voucherId == undefined || voucherId == null) {
      return;
    }
    let refundId = this.$route.query.refundId;
    if (refundId == undefined || refundId == null) {
      return;
    }
    this.voucherId = Number(voucherId);
    this.refundId = Number(refundId);
    if (this.voucherId != 0) {
      this.getVoucherExpenseData(this.voucherId, this.refundId);
      this.getCompanyInfo();
    }
  },
  methods: {
    getVoucherExpenseData: function (voucherId, refundId) {
      voucherService
        .getExpenseVoucherDataForPrint(voucherId, refundId)
        .then((response) => {
          console.log(response);
          this.voucher = JSON.parse(JSON.stringify(response));
          var expendRow = 3 - this.voucher.refundWay.length;
          for (var i = 0; i < expendRow; i++) {
            this.voucher.refundWay.push({
              refundWayId: i - 6,
              fromSector: {
                sectorId: 0,
                shortName: " ",
              },
              toSector: {
                sectorId: 0,
                shortName: " ",
              },
              pass: 0,
              amount: 0,
            });
          }
        });
    },
    printSaleVoucher: function () {
      window.print();
    },
    // account
    getCompanyInfo: function () {
      companyInfoService.getCompanyInfo().then((response) => {
        this.company = Object.assign({}, response);
        this.file = `${axios.defaults.baseURL}/companyLogo/${response.logo}`;
      });
    },
  },
  filters: {
    perssengerCount: function (person) {
      if (person < 9) return "0" + person;
      return person;
    },
  },
  components: {
    "expense-voucher": expenseVoucher,
  },
};
</script>
<style scoped>
.customfont {
  font-size: 0.5em;
}
@font-face {
  font-family: "Handage";
  src: url("/font/HANDA__.woff") format("woff");
  src: url("/font/HANDA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haettenschweiler";
  src: url("/font/Haettenschweiler.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.heading-font {
  font-family: "Handage";
  letter-spacing: 0.3em;
}
.first-heading-font {
  font-family: "Haettenschweiler" !important;
  font-weight: 300;
  letter-spacing: 0.2em !important;
}
.vertical-dotted-divider {
  border-top: 2px dotted black;
}
.hori-dotted-divider {
  border-left: 2px dotted black;
}
.vertical-divider {
  border-top: 2px solid black;
}
.underlineVoucher {
  padding-bottom: 1px;
  border-bottom: dotted 2px black;
}
.simpleTable td,
.simpleTable th {
  border: 1px solid black;
  border-radius: 7px;
  /* white-space: pre-wrap; */
  padding-left: 10px;
  padding-right: 10px;
}
.noBorder {
  border: 0px !important;
}
.innerTable {
  border-collapse: collapse;
  border-style: hidden;
}
.innerTable td,
.innerTable th {
  border: 1px solid black;
}
.innerTable tr:first-child th {
  border-top: 0px;
}
.innerTable tr:last-child td {
  border-bottom: 0;
}
.innerTable tr td:first-child,
.innerTable tr th:first-child {
  border-left: 0;
}
.innerTable tr td:last-child,
.innerTable tr th:last-child {
  border-right: 0;
}

/* td::before { */
/* content: " "; */
/* } */
</style>
